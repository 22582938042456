<template>
  <div>
    <vue-type
      title="Tipo de cargo"
      permission-type="tipocargo"
      url-get-register="/v1/tipo-cargo/get/"
      url-delete-register="/v1/tipo-cargo/get/delete"
      url-active-register="/v1/tipo-cargo/update/active/"
      url-delete-register-one="/v1/tipo-cargo/delete/"
      url-delete-all="/v1/tipo-cargo/delete/"
      url-delete-register-back="/v1/tipo-cargo/delete/restore/"
      url-delete-all-back="/v1/tipo-cargo/delete/restoreall"
      url-update-one="/v1/tipo-cargo/update/"
      url-create-one="/v1/tipo-cargo/create"
    />
  </div>
</template>
<script>
import TypeOpcion from '@/components/optionsCrud/typeIndex.vue'

export default {
  components: {
    'vue-type': TypeOpcion,
  },
}
</script>
